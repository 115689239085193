import { Component, OnInit, OnDestroy } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { take } from "rxjs/operators";

import { Constants } from "../../../../constants/constants";
import { ModalService } from "../../../../components/shared/modals/modal.service";
import { UsersService } from "../../../account-management/users/users.service";
import { ApiKey } from "../../../../models/shared";

import { ApiKeysService } from "../api-keys.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../../services/title.service";
import { ClipboardService } from "ngx-clipboard";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { ZxTableActionsComponent } from "src/app/components/shared/zx-table-actions/zx-table-actions.component";
import { assignGenericLinkTextInputsFactory } from "src/app/components/shared/zx-table-actions/zx-table-actions.table-adapter";
import { ZxShowCopyLinksComponent } from "src/app/components/shared/zx-show-copy-links/zx-show-copy-links.component";
import { assignShowCopyLinksInputsFactory } from "src/app/components/shared/zx-show-copy-links/zx-show-copy-links.table-adapter";

@Component({
    selector: "app-api-key-list",
    templateUrl: "./api-key-list.component.html"
})
export class ApiKeyListComponent implements OnInit, OnDestroy {
    loading = true;
    refreshing = false;
    apiKeys: ApiKey[];
    selectedRows: Array<ApiKey> = [];

    isAdmin: boolean;
    urls = Constants.urls;

    private apiKeysSubscription: Subscription;

    private apiKeysBS$ = new BehaviorSubject<ApiKey[]>([]);

    tableColumnsSchema: TableSchema[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            width: 280,
            visible: true,
            sticky: 1,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<ApiKey>(
                row => row.name,
                row => row.name,
                () => true
            ),
            sortBy: (row: ApiKey): string => row.name,
            textValue: (row: ApiKey): string => row.name
        },
        {
            header: this.translate.instant("KEY"),
            columnDef: "key",
            width: 280,
            visible: true,
            component: ZxShowCopyLinksComponent,
            assignComponentsInputs: assignShowCopyLinksInputsFactory<ApiKey>(
                (row: ApiKey): Promise<void> => this.toggleApiKeyValue(row),
                (row: ApiKey) => this.copyString(row.key)
            )
        },
        {
            header: this.translate.instant("IS_READ_ONLY"),
            columnDef: "is_read_only",
            width: 280,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<ApiKey>(
                (row: ApiKey): string => this.convertBooleanToStr(row),
                (row: ApiKey): string => this.convertBooleanToStr(row),
                () => true
            ),
            textValue: (row: ApiKey): string => this.convertBooleanToStr(row)
        },
        {
            header: "",
            columnDef: "actions",
            width: 40,
            align: "right",
            visible: true,
            stickyToLast: true,
            component: ZxTableActionsComponent,
            assignComponentsInputs: assignGenericLinkTextInputsFactory([
                {
                    icon: "trash-alt",
                    translateTitle: "DELETE",
                    cssClasses: "text-danger",
                    onClickHandler: (row: ApiKey) => this.delete(row)
                }
            ])
        }
    ];

    constructor(
        private ak: ApiKeysService,
        private modalService: ModalService,
        private userService: UsersService,
        private mixpanelService: MixpanelService,
        private translate: TranslateService,
        private titleService: TitleService,
        private cbs: ClipboardService
    ) {
        // Set Title
        this.titleService.setTitle("API_KEYS", "");
    }

    ngOnInit() {
        this.loading = true;

        // isAdmin
        this.userService.isAdmin.pipe(take(1)).subscribe(bool => {
            this.isAdmin = bool;
        });

        this.apiKeysSubscription = this.ak.apiKeys.subscribe(apiKeys => {
            this.apiKeys = apiKeys;
            if (this.apiKeys) {
                this.prepTableData();
                this.loading = false;
            }
        });
    }

    ngOnDestroy() {
        for (const key of this.apiKeys) {
            key.key = null;
        }

        this.apiKeysSubscription.unsubscribe();
    }

    async toggleApiKeyValue(apiKey: ApiKey) {
        if (apiKey.key) {
            apiKey.key = null;
            return;
        }

        const fullKey = await this.ak.getApiKeyValue(apiKey);
        if (fullKey) apiKey.key = fullKey.key;
    }

    async refresh() {
        this.refreshing = true;

        const apiKeys = this.ak.refreshApiKeys().toPromise();
        await Promise.all([apiKeys]);

        this.refreshing = false;
    }

    async multiAction(action: string, func: (apiKey: ApiKey) => Promise<unknown>) {
        const result = await this.modalService.confirmMultiple(action, "API_KEY", this.selectedRows, func);
        if (result.actionTaken) {
            this.mixpanelService.sendEvent(this.translate.instant(action).toLowerCase() + " multiple api keys");
            if (action === "DELETE") this.selectedRows = [];
        }
    }

    multiDelete() {
        this.multiAction("DELETE", async (apiKey: ApiKey) => this.ak.deleteApiKey(apiKey));
    }

    async delete(apiKey: ApiKey) {
        await this.modalService.confirm(
            "DELETE",
            "API_KEY",
            async () => {
                const id = apiKey.id;
                const result = await this.ak.deleteApiKey(apiKey);
                if (result) {
                    this.mixpanelService.sendEvent("delete api key", { id });
                } else return false;
            },
            apiKey.name
        );
    }

    get apiKeys$() {
        return this.apiKeysBS$.asObservable();
    }

    private prepTableData() {
        if (this.apiKeys) {
            this.apiKeysBS$.next([...this.apiKeys]);
        }
    }

    copyString(pw: string) {
        this.cbs.copy(pw);
    }

    convertBooleanToStr(row: ApiKey): string {
        return row.is_read_only ? "True" : "False";
    }
}
